import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';

import withHocName from './with-hoc-name';
import withLayoutProps from './with-layout-props';
import { getFontSize } from '../selectors/app-settings-selectors';

const mapRuntimeToProps = (state, { section, layoutType }) => ({
  postTitleFontSize: getFontSize(state, layoutType, section, 'title'),
  postDescriptionFontSize: getFontSize(
    state,
    layoutType,
    section,
    'description',
  ),
  postMetadataFontSize: getFontSize(state, layoutType, section, 'metadata'),
});

export default flowRight(
  withLayoutProps(),
  connect(mapRuntimeToProps),
  withHocName('WithPostFontSize'),
);
