import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Img } from '@wix/communities-image-lib';

import withResponsiveContext from '../responsive-listener/with-responsive-context';
import { getMobileViewportHeight } from '../../services/viewport';
import { connect } from '../runtime-context';
import { isSSR } from '../../store/basic-params/basic-params-selectors';
import OnScreen from '../on-screen';
import { getImageDimensions } from './get-image-dimensions';
import styles from './post-list-item-image.scss';

export const PostListItemImageMobile = ({
  image,
  url,
  isPublic,
  rootWidth,
  width,
  height,
  layoutName,
  isSSR,
}) => {
  const props = isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true };

  const dimensions = getImageDimensions({
    container: { width, height },
    component: { width: rootWidth },
    layoutName,
    isSSR,
  });

  return (
    <div className={classNames(styles.container, styles.mobile)}>
      <OnScreen threshold={getMobileViewportHeight()}>
        {({ isOnScreen, setRef }) => (
          <Img
            src={image}
            className={styles.image}
            ref={setRef}
            isOnScreen={isOnScreen}
            alt=""
            {...props}
            {...dimensions}
          />
        )}
      </OnScreen>
    </div>
  );
};

PostListItemImageMobile.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  url: PropTypes.string,
  isPublic: PropTypes.bool,
  rootWidth: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  layoutName: PropTypes.string.isRequired,
  isSSR: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state) => ({
  isSSR: isSSR(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withResponsiveContext,
)(PostListItemImageMobile);
